<template>
  <div class="row" v-if="pendientes.length > 0">
    <div class="col-md-12">
      <div class="alert alert-danger" role="alert">No podrá subir sus facturas hasta que se hayan resuelto todos sus pendientes</div>
      <div class="alert alert-danger" role="alert">
        <span v-for="item in pendientes" :key="item" v-html="`- ${item}<br>`"></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-flush h-xl-100">
        <div class="card-header pt-7">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold text-gray-800">Facturas</span>
          </h3>
          <div class="card-toolbar">
            <div class="d-flex flex-stack flex-wrap gap-4">
              <div class="d-flex align-items-center fw-bold">
                <div class="text-gray-400 fs-7 me-2">Mostrar</div>
                <Select2 v-model="status" :options="estatus" class="min-w-200px w-100" :settings="{
                  placeholder: 'Activas',
                  minimumResultsForSearch: -1,
                }" />
              </div>
              <div class="position-relative my-1 d-none">
                <span class="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                      transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"></path>
                  </svg>
                </span>
                <input type="text" data-kt-table-widget-4="search" class="form-control w-150px fs-7 ps-12"
                  placeholder="Buscar" v-model="filtro" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-2">
          <div id="kt_table_widget_4_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
            <div class="table-responsive">
              <table class="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer"
                id="kt_table_widget_4_table">
                <thead>
                  <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                    <th>Embarque</th>
                    <th>Empresa</th>
                    <th>Servicio</th>
                    <th class="text-center">Fecha</th>
                    <th class="text-center">Factura</th>
                    <th class="text-center">Estatus</th>
                    <th class="">Nota</th>
                  </tr>
                </thead>
                <tbody class="fw-bold1 text-gray-600">
                  <tr v-if="facturasLista.length <= 0">
                    <td colspan="6">Sin resultados</td>
                  </tr>
                  <tr v-for="row in facturasLista" :key="row.id">
                    <td>
                      <a class="text-gray-800 text-hover-primary">{{
                        row.embarque
                      }}</a>
                    </td>
                    <td>{{ row.empresa }}</td>
                    <td>{{ row.servicio }}</td>
                    <td class="text-center">{{ row.fecha }}</td>
                    <td class="text-center">
                      {{ row.factura }}
                    </td>

                    <td class="text-center">
                      <button @click="handleDetalle(row.id)" 
                        class="btn btn-sm py-3 px-4 fs-7 text-center cursor-pointer"
                        :class="`btn-${row.color}`"
                        :disabled="pendientes.length > 0 && row.status == 'Sin Facturar'"
                        >{{ row.status }}</button>
                    </td>
                    <td>{{ row.nota }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
              </div>
              <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal v-if="isOpenDetalle" :height="'600px'">
    <template v-slot:header>
      <div class="row p-2">
        <div class="col-10">
          <h5>{{ formaTitulo }}</h5>
        </div>
        <div class="col-2 text-end">
          <a @click="isOpenDetalle = false" class="cursor-pointer"><font-awesome-icon icon="fa-window-close"
              size="2x" /></a>
        </div>
        <div class="col-md-12">
          <hr />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="row p-2">
        <div class="col-md-12">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td colspan="2"><b>Datos del servicio:</b></td>
              </tr>
              <tr>
                <td class="w-125px">Embarque:</td>
                <td>{{ itemDetalle.fecha }}</td>
              </tr>
              <tr>
                <td>Servicio:</td>
                <td>{{ itemDetalle.servicio }}</td>
              </tr>
              <tr>
                <td>Total:</td>
                <td>{{ itemDetalle.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-md-12" v-if="
          ['sin facturar', 'rechazada', 'en revision'].includes(
            itemDetalle.status.toLowerCase()
          )
        ">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>Favor de seleccionar el archivo XML de la factura</td>
                <td>
                  <button class="btn btn-primary btn-table w-100" type="button" @click="handleSeleccionarXml"
                    :disabled="validando">
                    {{ btnValidarXmlTexto }}
                  </button>
                  <small>{{ nombreArchivoXml }}</small>
                  <input type="file" id="xmlFile" accept="text/xml" class="d-none" @change="handleChangeXml" />
                </td>
              </tr>
              <template v-if="errores.length > 0">
                <tr>
                  <td colspan="2">
                    <b><span class="text-danger">Se han encontrado errores en el comprobante:</span></b>
                    <ul>
                      <li v-for="error in errores" :key="error">{{ error }}</li>
                    </ul>
                  </td>
                </tr>
              </template>
              <template v-if="formaDetalle.archivoXml && errores.length == 0">
                <tr>
                  <td>Factura:</td>
                  <td>
                    <input type="text" class="form-control form-control-sm" v-model="formaDetalle.factura"
                      :disabled="facturaDisabled" />
                  </td>
                </tr>
                <tr>
                  <td>Fecha:</td>
                  <td>
                    <input type="text" class="form-control form-control-sm" v-model="formaDetalle.fecha" disabled />
                  </td>
                </tr>
                <tr>
                  <td>Archivo PDF:</td>
                  <td>
                    <button class="btn btn-primary btn-table w-100" type="button" @click="handleSeleccionarPDF">
                      Seleccionar PDF
                    </button>
                    <input type="file" id="pdfFile" accept="application/pdf" class="d-none" @change="handleChangePDF" />
                    <small>{{ nombreArchivoPdf }}</small>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-end">
                    <button class="btn btn-success btn-sm" type="button" @click="handleGuardarFactura">
                      Guardar Factura
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-12" v-else-if="['pagada'].includes(itemDetalle.status.toLowerCase())">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>Favor de seleccionar el archivo XML del complemento de pago</td>
                <td>
                  <button class="btn btn-primary btn-table w-100" type="button" @click="handleSeleccionarXmlComprobante"
                    :disabled="validandoComprobante">
                    {{ btnValidarXmlTextoComprobante }}
                  </button>
                  <small>{{ nombreArchivoXmlComprobante }}</small>
                  <input type="file" id="xmlFileComprobante" accept="text/xml" class="d-none"
                    @change="handleChangeXmlComprobante" />
                </td>
              </tr>
              <template v-if="erroresComprobante.length > 0">
                <tr>
                  <td colspan="2">
                    <b><span class="text-danger">Se han encontrado errores en el comprobante:</span></b>
                    <ul>
                      <li v-for="error in erroresComprobante" :key="error">{{ error }}</li>
                    </ul>
                  </td>
                </tr>
              </template>
              <template v-if="formaDetalle.archivoXmlComprobante && erroresComprobante.length == 0">
                <tr>
                  <td>Archivo PDF:</td>
                  <td>
                    <button class="btn btn-primary btn-table w-100" type="button"
                      @click="handleSeleccionarPDFComprobante">
                      Seleccionar PDF
                    </button>
                    <input type="file" id="pdfFileComprobante" accept="application/pdf" class="d-none"
                      @change="handleChangePDFComprobante" />
                    <small>{{ nombreArchivoPdfComprobante }}</small>
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-end">
                    <button class="btn btn-success btn-sm" type="button" @click="handleGuardarComprobante">
                      Guardar Comprobante
                    </button>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-12" v-else>
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td class="w-125px">Factura:</td>
                <td>{{ itemDetalle.factura }}</td>
              </tr>
              <tr v-if="itemDetalle.documentos.length > 0">
                <td colspan="2"><b>Documentos requeridos:</b></td>
              </tr>
              <tr v-for="doc in itemDetalle.documentos" :key="doc.id">
                <td>{{ doc.documento }}</td>
                <td>
                  <a v-if="doc.link.length > 0" :href="doc.link" target="_blank" :download="doc.archivo"
                    class="mx-5"><font-awesome-icon icon="download" /></a>
                  <button class="btn btn-primary btn-table" :class="{
                    'btn-danger': doc.link.length <= 0,
                    'btn-success': doc.link.length > 0,
                  }" @click="handleSeleccionarDocumento(doc.id)">
                    Subir Archivo
                  </button>
                </td>
              </tr>
              <tr v-if="itemDetalle.documentos.length > 0">
                <td colspan="2">
                  <b>** Todos los documentos estan sujetos a revisión:</b>
                </td>
              </tr>
            </tbody>
          </table>
          <input type="file" id="docFacFile" accept="application/pdf" class="d-none"
            @change="handleDocumentoFacturaChange" />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { ref, watch, watchEffect } from "vue";
import useFacturas from "../composables/useFacturas";
import Select2 from "vue3-select2-component";
import Modal from "../../../components/Modal.vue";
import Swal from "sweetalert2";
import { pendientes } from "../store/getters";
export default {
  components: { Select2, Modal },
  setup() {
    const {
      getFacturas,
      facturas,
      estatus,
      setValidarXML,
      setValidarXMLComprobante,
      validando,
      validandoComprobante,
      formaDetalleIni,
      setFacturar,
      setComprobante,
      guardando,
      formaDocumentoFacturaIni,
      subiendo,
      pendientes,
      setSubirDocumentoFactura,
      getEstatus
    } = useFacturas();
    const facturasLista = ref([]);
    const facturaDisabled = ref(true)
    const filtro = ref("");
    const status = ref("Activas");
    const isOpenDetalle = ref(false);
    const formaTitulo = ref("");
    const itemDetalle = ref();
    const btnValidarXmlTexto = ref("Seleccionar XML");
    const btnValidarXmlTextoComprobante = ref("Seleccionar XML");
    const errores = ref([]);
    const erroresComprobante = ref([]);
    const formaDetalle = ref(formaDetalleIni);
    const nombreArchivoXml = ref("");
    const nombreArchivoPdf = ref("");
    const formaDocumentoFactura = ref(formaDocumentoFacturaIni);
    const nombreArchivoXmlComprobante = ref("");
    const nombreArchivoPdfComprobante = ref("");

    getFacturas(status.value);
    getEstatus()

    const filtrarFacturas = () => {
      getFacturas(status.value);
    };

    const handleDetalle = (cid) => {
      formaDetalle.value = formaDetalleIni;
      const item = facturasLista.value.find((item) => item.id === cid);
      formaDetalle.value = { ...formaDetalleIni, detalle: item.id };
      formaTitulo.value = `Detalle del embarque: ${item.embarque}`;
      itemDetalle.value = { ...item };

      btnValidarXmlTexto.value = "Seleccionar XML";
      btnValidarXmlTextoComprobante.value = "Seleccionar XML";
      errores.value = [];
      nombreArchivoXml.value = "";
      nombreArchivoPdf.value = "";
      nombreArchivoXmlComprobante.value = "";
      nombreArchivoPdfComprobante.value = "";

      isOpenDetalle.value = true;
    };

    const handleSeleccionarXml = () => {
      document.querySelector("#xmlFile").click();
    };

    const handleSeleccionarXmlComprobante = () => {
      document.querySelector("#xmlFileComprobante").click();
    };

    const handleSeleccionarPDF = () => {
      document.querySelector("#pdfFile").click();
    };

    const handleSeleccionarPDFComprobante = () => {
      document.querySelector("#pdfFileComprobante").click();
    };

    const handleChangePDF = (event) => {
      const file = event.target.files[0];
      if (file) {
        nombreArchivoPdf.value = file.name;
        formaDetalle.value = {
          ...formaDetalle.value,
          archivoPdf: file,
        };
      }
    };

    const handleChangePDFComprobante = (event) => {
      const file = event.target.files[0];
      if (file) {
        nombreArchivoPdfComprobante.value = file.name;
        formaDetalle.value = {
          ...formaDetalle.value,
          archivoPdfComprobante: file,
        };
      }
    };

    const handleChangeXml = async (event) => {
      const file = event.target.files[0];
      if (file) {
        nombreArchivoXml.value = file.name;
        const fp = new FormData();
        fp.append("archivo", file);
        fp.append("detalle", itemDetalle.value.id);
        const result = await setValidarXML(fp);
        if (result.errores) {
          errores.value = result.errores;
        }


        facturaDisabled.value = result.factura != null

        formaDetalle.value = {
          ...formaDetalle.value,
          factura: result.factura,
          fecha: result.fecha,
          archivoXml: file,
        };
      }
    };

    const handleChangeXmlComprobante = async (event) => {
      const file = event.target.files[0];
      if (file) {
        nombreArchivoXmlComprobante.value = file.name;
        const fp = new FormData();
        fp.append("archivo", file);
        fp.append("detalle", itemDetalle.value.id);
        const result = await setValidarXMLComprobante(fp);
        if (result.errores) {
          erroresComprobante.value = result.errores;
        }


        facturaDisabled.value = result.factura != null

        formaDetalle.value = {
          ...formaDetalle.value,
          archivoXmlComprobante: file,
        };
      }
    };


    const handleGuardarFactura = () => {

      if (!formaDetalle.value.archivoXml) {
        Swal.fire("Atención", "Favor de seleccionar el archivo XML");
        return;
      }

      if (!formaDetalle.value.archivoPdf) {
        Swal.fire("Atención", "Favor de seleccionar el archivo PDF");
        return;
      }

      if (!formaDetalle.value.factura || formaDetalle.value.factura.length <= 0) {
        Swal.fire("Atención", "No se encontró el folio de la factura");
        return;
      }

      if (!formaDetalle.value.fecha || formaDetalle.value.fecha.length <= 0) {
        Swal.fire("Atención", "No se encontró la fecha de la factura");
        return;
      }

      Swal.fire({
        title: "Confirmar",
        text: "Se enviará la información a Vento Tranport para su revisión",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Enviar!",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const fp = new FormData();
          for (var key in formaDetalle.value) {
            fp.append(key, formaDetalle.value[key]);
          }
          const res = await setFacturar(fp);
          if (res.ok) {
            isOpenDetalle.value = false;
            getFacturas(status.value);
          } else {
            Swal.fire("Error", res.message, "error");
          }
        }
      });
    };

    const handleGuardarComprobante = () => {

      if (!formaDetalle.value.archivoXmlComprobante) {
        Swal.fire("Atención", "Favor de seleccionar el archivo XML");
        return;
      }

      if (!formaDetalle.value.archivoPdfComprobante) {
        Swal.fire("Atención", "Favor de seleccionar el archivo PDF");
        return;
      }

      

      Swal.fire({
        title: "Confirmar",
        text: "Se enviará la información a Vento Tranport para su revisión",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Enviar!",
        cancelButtonText: "No, cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const fp = new FormData();
          for (var key in formaDetalle.value) {
            fp.append(key, formaDetalle.value[key]);
          }
          const res = await setComprobante(fp);
          if (res.ok) {
            isOpenDetalle.value = false;
            getFacturas(status.value);
          } else {
            Swal.fire("Error", res.message, "error");
          }
        }
      });
    };

    const handleSeleccionarDocumento = (documento) => {
      formaDocumentoFactura.value.documento = documento;
      formaDocumentoFactura.value.detalle = itemDetalle.value.id;
      document.querySelector("#docFacFile").click();
    };

    const handleDocumentoFacturaChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        formaDocumentoFactura.value.archivo = file;
        const fp = new FormData();
        for (var key in formaDocumentoFactura.value) {
          fp.append(key, formaDocumentoFactura.value[key]);
        }
        const result = await setSubirDocumentoFactura(fp);
        if (result.ok) {
          getFacturas(status.value).then((result) => {
            const item = facturasLista.value.find(
              (item) => item.id === itemDetalle.value.id
            );
            itemDetalle.value = { ...item };
          });
        } else {
          Swal.fire("Error", result.message, "error");
        }
      }
    };

    watch(
      () => facturas.value,
      () => {
        facturasLista.value = facturas.value;
      }
    );

    watch(
      () => status.value,
      () => {
        filtrarFacturas();
      }
    );

    watch(
      () => validando.value,
      () => {
        if (validando.value) {
          btnValidarXmlTexto.value = "Validando";
        } else {
          btnValidarXmlTexto.value = "Seleccionar XML";
        }
      }
    );

    watch(
      () => validandoComprobante.value,
      () => {
        if (validandoComprobante.value) {
          btnValidarXmlTextoComprobante.value = "Validando";
        } else {
          btnValidarXmlTextoComprobante.value = "Seleccionar XML";
        }
      }
    );

    watchEffect(() => validando.value);
    watchEffect(() => isOpenDetalle.value);

    return {
      facturasLista,
      estatus,
      filtro,
      status,
      isOpenDetalle,
      itemDetalle,
      formaTitulo,
      handleDetalle,
      handleSeleccionarXml,
      handleSeleccionarXmlComprobante,
      handleChangeXml,
      handleChangeXmlComprobante,
      btnValidarXmlTexto,
      btnValidarXmlTextoComprobante,
      validando,
      validandoComprobante,
      pendientes,
      errores,
      erroresComprobante,
      formaDetalle,
      facturaDisabled,
      nombreArchivoXml,
      nombreArchivoXmlComprobante,
      nombreArchivoPdf,
      nombreArchivoPdfComprobante,
      handleSeleccionarPDF,
      handleSeleccionarPDFComprobante,
      handleChangePDF,
      handleChangePDFComprobante,
      handleGuardarFactura,
      handleGuardarComprobante,
      handleSeleccionarDocumento,
      handleDocumentoFacturaChange,
    };
  },
};
</script>

<style scoped>
.select2-container {
  width: 100% !important;
}
</style>