import {computed} from "vue"
import {useStore} from "vuex"

const useFacturas = () => {
  const store = useStore()

  const getFacturas = async (status) => {
    return await store.dispatch("facturas/getFacturas", {status})
  }

  const getEstatus = async () => {
    return await store.dispatch("facturas/getEstatus");
  }

  const setValidarXML = async (datos) => {
    return await store.dispatch("facturas/setValidarXML", datos)
  }


  const setValidarXMLComprobante = async (datos) => {
    return await store.dispatch("facturas/setValidarXMLComprobante", datos)
  }

  const setFacturar = async (datos) => {
    return await store.dispatch("facturas/setFacturar", datos)
  }


  const setComprobante = async (datos) => {
    return await store.dispatch("facturas/setComprobante", datos)
  }

  const setSubirDocumentoFactura = async (datos) => {
    return await store.dispatch("facturas/setSubirDocumentoFactura", datos)
  }

  const formaDetalleIni = {
    archivoXml: null,
    archivoPdf: null,
    archivoXmlComprobante: null,
    archivoPdfComprobante: null,
    factura: "",
    fecha: "",
    detalle: "",
  }

  const formaDocumentoFacturaIni = {
    detalle: "",
    documento: "",
    archivo: null,
  }

  return {
    formaDetalleIni,
    formaDocumentoFacturaIni,
    facturas: computed(() => store.getters["facturas/facturas"]),
    estatus: computed(() => store.getters["facturas/estatus"]),
    validando: computed(() => store.getters["facturas/validando"]),
    validandoComprobante: computed(() => store.getters["facturas/validandoComprobante"]),
    guardando: computed(() => store.getters["facturas/guardando"]),
    subiendo: computed(() => store.getters["facturas/subiendo"]),
    pendientes: computed(() => store.getters["facturas/pendientes"]),
    getFacturas,
    setValidarXML,
    setValidarXMLComprobante,
    setFacturar,
    setComprobante,
    setSubirDocumentoFactura,
    getEstatus
  }
}

export default useFacturas
